<template>
    <div class="cbox JobContainer">
     
      <iframe v-if="code" style="width:100%;height:100%;border:0" :src="url"></iframe>
  
    </div>
  </template>
  <script>

  export default {
    name: 'deptRole',
    data() {
      return {
        code: '',
        url:"http://sx.qushixi.org.cn/91job/#/tptj?xxdm="
      }
    },
    mounted() {
      this.getConfig()
    },
    methods: {
      getConfig() {
        this.$http.post("/api/sys_sch_config_list", {
          keyword: "91job_code",
          ktype: "ktype"
        }).then(res => {
          for (let item of res.data.data) {
            this.code = item.kvalue
            this.url  = this.url +item.kvalue
          }
          if(!this.code){
            this.$message.error("未设置91job学校代码")
          }
        })
      }
    }
  }
  </script>